import { ModuleTree } from 'vuex';
import IRootState from './rootState';
import serviceWorker from './serviceWorkerStore';

const stores = { serviceWorker } as ModuleTree<IRootState>;

export * from './serviceWorkerStore';
export * from './rootState';
export { stores, serviceWorker as serviceWorkerStore };
export default stores;
