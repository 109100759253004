import { Module } from 'vuex';
import { IRootState } from '@/stores';

interface IServiceWorkerState {
    appIsStale: { stale: boolean; callback: () => void };
}

class ServiceWorkerStore implements Module<IServiceWorkerState, IRootState> {
    namespaced = true;

    state: IServiceWorkerState = {
        appIsStale: {
            stale: false,
            callback: (): void => {
                return;
            }
        }
    };

    mutations = {
        setAsStale(s: IServiceWorkerState, callback: () => void): void {
            s.appIsStale = { stale: true, callback };
        }
    };
}

export { IServiceWorkerState, ServiceWorkerStore };

// export an instance of the store by default
export default new ServiceWorkerStore();
